@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500&display=swap);
body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  body-moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

html {
  scroll-behavior: smooth;
  /* width */
}

body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
/* body::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.header {
  width: calc(100% - 40px);
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

@media only screen and (max-width: 800px) {
  .header {
    /* calc() */
  }
}

.logo {
  width: 150px;
  transition: all 0.5s;
}

.logo:hover {
  width: 150px;
  -webkit-transform: rotateY(40deg);
          transform: rotateY(40deg);
}

.header-buttons {
  display: table;
  float: right;
  padding: 20px;
}

.header-button-close-button {
  display: none;
}

.header-button-open-button {
  display: none;
}

@media only screen and (max-width: 800px) {
  .header-buttons {
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 250px;
    height: 110px;
    display: block;
    position: absolute;
    right: -10px;
    top: 10px;
    transition: all 0.2s;
  }

  .header-buttons.collapsed {
    right: -250px;
    display: none;
  }

  .header-button-open-button {
    opacity: 1;
    display: table;
    outline: none;
    border: none;
    color: #555;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    right: 20px;
    top: 20px;
    padding-top: 5px;
    text-align: center;
  }

  .header-button-open-button-icon {
    width: 25px;
    margin: auto;
  }

  .header-button-close-button {
    outline: none;
    border: none;
    display: table;
    font-weight: 500;
    position: absolute;
    z-index: 1000;
    color: white;
    width: 60px;
    height: 60px;
    padding-top: 5px;
    border-radius: 100%;
    background-color: rgb(212, 212, 212);
    font-size: 15px;
    text-align: center;
    top: 20px;
    right: 20px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    text-align: center;
  }
}

.header-button {
  font-weight: 400;
  font-size: 25px;
  text-decoration: none;
  margin-left: 15px;
  color: #414c99;
  border-bottom: 2px solid #ccc;
  transition: all 0.1s;
  padding-bottom: 1px;
}

@media only screen and (max-width: 800px) {
  .header-button {
    width: 100%;
    font-size: 20px;
    clear: both;
    display: block;
    border: none;
    margin: 0;
    margin-bottom: 10px;
  }
}

.header-button:hover {
  color: #08145d;
  padding-bottom: 5px;
  border-color: #aaa;
}

#body {
  padding-top: 70px;
}

@media only screen and (max-width: 800px) {
  #body {
    padding-top: 0px;
  }
}

#threshold-left-image {
  position: absolute;
  background-size: cover;
  width: 850px;
  height: 450px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 800px) {
  #threshold-left-image {
    display: none;
  }
}

#slogan-wrapper {
  width: 650px;
  margin: auto;
  height: 100px;
  position: relative;
  top: 50px;
}

@media only screen and (max-width: 800px) {
  #slogan-wrapper {
    width: calc(100% - 40px);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    padding-left: 10px;
  }
}

#threshold-slogan {
  width: 350px;
  float: right;
}

@media only screen and (max-width: 800px) {
  #threshold-slogan {
    width: calc(100% - 20px);
    float: auto;
  }
}

#about-right-image {
  background-size: cover;
  width: 1000px;
  position: absolute;
  right: 0;
  top: 400px;
  height: 800px;
  z-index: -1;
}

@media only screen and (max-width: 800px) {
  #about-right-image {
    width: 800px;
    height: 400px;
    display: none;
  }
}

#about {
  margin-top: 400px;
}

@media only screen and (max-width: 800px) {
  #about {
    margin-top: 150px;
  }
}

#about-box {
  width: 650px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  padding: 60px;
  margin: auto;
  z-index: 1000;
  margin-left: 250px;
  border-radius: 3px;
}

@media only screen and (max-width: 800px) {
  #about-box {
    width: calc(100% - 60px);
    padding: 20px;
    margin: auto;
  }
}

.section-heading {
  color: #08145d;
  font-weight: 500;
  font-size: 30px;
}

.section-verbiage {
  font-size: 20px;
  padding: 20px 0;
}

.emphasis-primary {
  font-weight: 500;
}

.emphasis-secondary {
  font-weight: 500;
  color: #5d5d5d;
}

#leadership {
  position: relative;
  margin-top: 220px;
  display: table;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  #leadership {
  }
}

#leadership-left-image {
  background-size: cover;
  width: 750px;
  height: 1050px;
  position: absolute;
  left: 0;
  margin-top: -150px;
  z-index: -1;
}

@media only screen and (max-width: 800px) {
  #leadership-left-image {
    width: calc(100%);
    display: none;
  }
}

#leadership-box {
  width: 850px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  margin: auto;
  margin-right: 250px;
  z-index: 1000;
  border-radius: 3px;
}

@media only screen and (max-width: 800px) {
  #leadership-box {
    width: calc(100% - 20px);
    margin: auto;
    margin-top: -170px;
  }
}

#leadership-box-content {
  padding: 60px;
}

@media only screen and (max-width: 800px) {
  #leadership-box-content {
    padding: 20px;
  }
}

#leadership-bios-wrapper {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: rgb(247, 247, 247);
  /* display: flex; */
  display: table;
}

@media only screen and (max-width: 800px) {
  #leadership-bios-wrapper {
    display: table;
  }
}

.leadership-bio {
  width: 100%;
  flex: 1 1;
  margin: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .leadership-bio {
    width: 100%;
    /* display: flex; */
    padding-bottom: 20px;
  }
}

.leadership-bio-primary {
  width: 100%;
  display: table;
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-primary {
  }
}

.leadership-bio-secondary {
  width: 100%;
  display: table;
  clear: both;
  padding-top: 5px;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-secondary {
  }
}

.leadership-bio-description {
  color: #444;
  font-weight: 400;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-description {
    font-size: 14px;
  }
}

.leadership-bio-photo {
  background-size: cover;
  width: 100px;
  height: 100px;
  display: block;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 5, 0.2);
  border-radius: 3px;
  margin-right: 20px;
  float: left;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-photo {
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 20px;
  }
}

.leadership-bio-name {
  color: #08145d;
  margin: auto;
  padding-top: 5px;
  font-size: 25px;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-name {
    font-size: 18px;
  }
}

.leadership-bio-email {
  width: 100%;
  font-size: 15px;
  color: #666;
  font-weight: 400;
}

.leadership-bio-email > a {
  color: #666;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-email {
    font-size: 12px;
  }
}

.leadership-bio-name-emphasis {
  font-weight: 500;
}

.leadership-bio-title {
  width: 100%;
  color: #aaa;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}

@media only screen and (max-width: 800px) {
  .leadership-bio-title {
    font-size: 13px;
  }
}

#contact {
  width: 100%;
  display: table;
  clear: both;
  margin-top: 50px;
  padding: 120px 0;
  background-color: rgb(218, 218, 218);
}

@media only screen and (max-width: 800px) {
  #contact {
    padding: 50px 0;
  }
}

#contact-box {
  width: 100%;
  max-width: 800px;
  height: 250px;
  margin: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

@media only screen and (max-width: 800px) {
  #contact-box {
    width: calc(100% - 40px);
    height: 500px;
  }
}

#contact-box-image {
  width: 250px;
  height: 250px;
  float: left;
  background-size: cover;
  border-radius: 3px 0 0 3px;
}

@media only screen and (max-width: 800px) {
  #contact-box-image {
    width: 100%;
    border-radius: 3px 3px 0 0;
  }
}

#contact-box-content {
  width: calc(100% - 330px);
  padding: 30px;
  float: right;
  padding-left: 50px;
}

@media only screen and (max-width: 800px) {
  #contact-box-content {
    padding: 10px;
    padding-top: 30px;
    width: calc(100% - 30px);
    clear: both;
  }
}

.contact-row {
  display: table;
  padding-top: 15px;
}

.contact-icon {
  float: left;
  width: 50px;
  height: 50px;
}

.contact-content {
  float: left;
  margin-left: 20px;
  padding-top: 15px;
  font-weight: 400;
}

.contact-content > a {
  text-decoration: none;
  color: #08145d;
}

#footer {
  background-color: #08145d;
  width: 100%;
  clear: both;
  display: table;
  padding: 20px 0;
}

#footer-content {
  color: white;
  max-width: 800px;
  margin: auto;
}

#footer-address {
  float: left;
}

#footer-copy {
  float: right;
}

@media only screen and (max-width: 800px) {
  #footer-address {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }

  #footer-copy {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
}

.background {
  position: absolute;
  margin-top: 550px;
}

@media only screen and (max-width: 800px) {
  .background {
    display: none;
  }
}

