@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500&display=swap");

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  body-moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

html {
  scroll-behavior: smooth;
  /* width */
}

body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
  transition: all 0.2s;
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
/* body::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
